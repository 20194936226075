// NA Configuration

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

import { getAuth } from 'firebase/auth'

const config = {
  apiKey: 'AIzaSyAidqvUMgcXjjKPrRETzZgDIapvlhTupWU',
  authDomain: 'mle10-1240d.firebaseapp.com',
  databaseURL: 'https://mle10-1240d.firebaseio.com',
  projectId: 'mle10-1240d',
  storageBucket: 'mle10-1240d.appspot.com',
  messagingSenderId: '909474319659',
  appId: '1:909474319659:web:3d0b71f57f82162c90096e',
  measurementId: 'G-F6M5P8N9Y3',
}

const firebaseApp = initializeApp(config)
const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
const auth = getAuth()

export { db, storage, auth }

export default {
  db,
  storage,
  auth,
}
